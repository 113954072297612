.hero-section {
    width: 100%;
    height: auto;
    background-color: var(--color-black);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 3rem;
    padding: 5rem 0;
}

.image-container {
    display: flex;
    flex: 1;
    margin-top: 2rem;
    margin-right: 2rem;
    align-items: center;
    justify-content: center;
}

.image-container img {
    width: 350px;
    height: 400px;
    z-index: 1;
}

.image-container .blank-square {
    width: 350px;
    height: 400px;
    border: solid var(--color-theme) 5px;
    position: absolute;
    margin-bottom: 2.5rem;
    margin-right: 2.5rem;
    background-color: var(--color-theme);
}

.image-container .blank-square1 {
    width: 350px;
    height: 400px;
    border: solid var(--color-theme) 5px;
    position: absolute;
    margin-top: 2.5rem;
    margin-left: 2.5rem;
    /* background-color: var(--color-theme); */
}

.hero-content {
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    flex: 1.5;
    justify-content: flex-start;
    align-items: center;
}

.hero-head h1 {
    font-size: 4rem;
    font-family: var(--font-family);
    margin: 1rem 2rem;
}

.p-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}

.p-container p {
    margin: 1rem 2rem;
    line-height: 1.5;
}

@media screen and (max-width : 920px) {
    .hero-section {
        width: 100%;
        padding-top: 4rem;
        height: auto;
        flex-direction: column;
        /* padding: 1.5rem 0 2rem 0; */
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
    }

    .hero-head h1 {
        font-size: 3rem;
    }

    .hero-content {
        flex: 0;
    }

    .p-container {
        width: 100%;
    }

    .image-container img {
        width: 200px;
        height: 250px;
    }

    .image-container .blank-square {
        width: 200px;
        height: 250px;
        margin-top: 1.5rem;
        margin-left: 1.5rem;
    }

    .image-container .blank-square1 {
        width: 200px;
        height: 250px;
        margin-top: 1.5rem;
        margin-left: 1.5rem;
    }

    .image-container {
        flex: 0;
        margin-top: 1rem;
    }
}