.gallery-container {
    width: 100%;
    height: auto;
    background-color: var(--color-black);
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gallery-head {
    font-family: var(--font-family);
    font-size: 4rem;
}

.gallery-images-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.gallery-text-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 2rem;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;
}

.gallery-text-content p {
    margin-top: 1rem;
    font-size: smaller;
    line-height: 1.3;
    font-weight: 500;
}

.photo-gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    /* margin: 0 1rem; */
    /* background-color: aqua; */
    padding: 3rem 1rem;
}

.photo-gallery-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;

}

.btn-view-photos {
    width: 5rem;
    padding: 7px 6px;
    margin: 1rem;
    font-weight: bold;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: var(--color-theme);
}

.images-containers {
    display: flex;
    flex-direction: row;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.images-containers::-webkit-scrollbar {
    display: none;
}

.photos-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 299px;
    height: 420px;
    margin-right: 2rem;
}

.photos-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: 0.5s ease;
}

.insta-link {
    position: absolute;
    font-size: 2rem;
    opacity: 0.5;
    transition: 0.5s ease;
    cursor: pointer;
}

/* .instagram-icon {
    position: absolute;
    font-size: 2rem;
    opacity: 0.5;
    transition: 0.5s ease;
    cursor: pointer;
} */

.insta-profile {
    text-decoration: none;
}

.photos-card:hover img {
    opacity: 0.40;
}

.photos-card:hover .insta-link {
    opacity: 1;
}

.image-scroll-arrows-contain {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    /* padding: 0 1rem; */
    bottom: 10%;
    /* background-color: #fff; */
}

.arrow-icon {
    color: white;
    font-size: 2rem;
    cursor: pointer;
    background: var(--color-theme);
    border-radius: 5px;
}

.arrow-icon:hover {
    background: var(--color-black);
    border-radius: 5px;
}

@media screen and (max-width: 920px) {
    .gallery-images-text {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .gallery-text-content {
        margin: 1rem;
    }

    .photo-gallery-container {
        max-width: 80%;
    }
}